import { ref } from 'vue';

const showSnackbar = ref(false);
const snackbarContent = ref(``);
const snackbarType = ref(`primary`);

export const useSnackbar = () => {
  const showError = (errorMsg) => {
    snackbarContent.value = errorMsg;
    snackbarType.value = `danger`;
    showSnackbar.value = true;
  };

  const showSuccess = (successMsg) => {
    snackbarContent.value = successMsg;
    snackbarType.value = `success`;
    showSnackbar.value = true;
  };

  const showInfo = (infoMsg) => {
    snackbarContent.value = infoMsg;
    snackbarType.value = `info`;
    showSnackbar.value = true;
  };

  return {
    showSnackbar,
    snackbarContent,
    snackbarType,
    showError,
    showSuccess,
    showInfo,
  };
};

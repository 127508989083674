<template>
  <v-snackbar v-model="showModel" :timeout="props.timeout" :color="props.type">
    <div class="me-5">
      {{ props.content }}
    </div>
    <template #actions>
      <button aria-label="Close modal" @click="showModel = false">
        <v-icon :icon="mdiCloseThick" />
      </button>
    </template>
  </v-snackbar>
</template>

<script setup>
import { mdiCloseThick } from '@mdi/js';

defineOptions({
  name: `Snackbar`,
});

const props = defineProps({
  content: {
    type: String,
    required: true,
  },
  type: {
    type: String,
    required: true,
  },
  timeout: {
    type: Number,
    default: 5000, // default timeout
  },
});

const showModel = defineModel({ type: Boolean });
</script>

<style>
.v-snackbar__actions {
  display: unset;
  margin-inline-end: 8px;
  position: absolute;
  top: 4px;
  right: 0;
}
</style>
